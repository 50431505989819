import React, { Fragment, useState } from "react";
import { Helmet } from "react-helmet";

import ChangeTempPassword from "./ChangeTempPassword";
//store
import { useStore } from "src/store/Store";
import { updateUser } from "src/store/userReducer";

import mobiscroll, { snackbar, toast } from "@mobiscroll/react";
import { Checkbox } from "@mobiscroll/react5";
import { Modal } from "react-bootstrap";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { getMyProfile } from "src/components/Dashboard/Profile/api";
import Loader from "src/components/Dashboard/common/Loader";
import line from "../../assets/Lines.svg";
import grid from "../../assets/grid.svg";
import login_icon from "../../assets/login icon  human.svg";
import doctor from "../../assets/login-doctor.svg";
import signin_circle from "../../assets/signin-circle.svg";
import { getQuery } from "../../components/ApolloConnection/ApolloProviderComponent";
import "../ApolloConnection/UnauthorizedConnection/ApolloProviderSignup";
import { getQueryUnAuth } from "../ApolloConnection/UnauthorizedConnection/ApolloProviderSignup";
import "../Dashboard/Layouts/Card.css";
import { GET_FILE_FOR_DOCG, USER_DATA } from "../graphql/GraphQLquery";
import "./Auth.scss";
import OtpVerification from "./OtpVerification";
import { isMobileOnly } from "react-device-detect";
import SetMFAasTOTP from "./SetMFAasTOTP";
import PDFViewer from "src/components/Dashboard/Layouts/PDFViewer";
import { signIn, signOut } from "@aws-amplify/auth";
import {
  fetchMFAPreference,
  fetchUserAttributes,
  getCurrentUser,
} from "@aws-amplify/auth";
const env = process.env.REACT_APP_ENVIRONMENT;
const tnc_file = process.env.REACT_APP_TNC;

function SignIn() {
  const history = useHistory();
  const [user, setUser] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [authenticating, setAuthenticating] = useState(false);
  const [congnitoUser, setCognitoUser] = useState(null);
  const [authType, setAuthType] = useState(null);
  const [errorLogin, setErrorLogin] = useState("");
  const [loader, setLoader] = useState(false);
  const [changeTempPassword, setChangeTempPassword] = useState(null);
  const [checked, setChecked] = useState(false);
  const [url, setUrl] = useState(process.env.REACT_APP_CLINIC_URL);
  const [showTNC, setShowTNC] = useState(false);
  const [userData, setUserData] = useState(null);
  const [mfa, setMfa] = useState(null);
  const [showPDF, setShowPDF] = useState(false);

  const { globalStateUser, dispatchUser } = useStore();

  const checkMFA = async () => {
    const user = await getCurrentUser();
    setUserData(user);
    const mfa = await fetchMFAPreference();
    return mfa;
  };
  // sdfsdf
  const minMaxLength = (text, minLength, maxLength) => {
    let result = !text || text.length < minLength;
    if (maxLength) result = result || text.length < minLength;
    return result;
  };

  const validEmail = (text) => {
    const regex = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    return !regex.test(text);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let currentFormErrors = {};

    switch (name) {
      case "username":
        setUser({ ...user, username: value });
        if (!value || validEmail(value)) {
          currentFormErrors[name] = `Email address is invalid`;
        } else {
          delete currentFormErrors[name];

          localStorage.setItem("username", value);
        }
        break;
      case "password":
        setUser({ ...user, password: value });
        if (minMaxLength(value, 8)) {
          currentFormErrors[name] = `Password should have minimum 8 characters`;
        } else {
          delete currentFormErrors[name];
        }
        break;
      default:
        break;
    }
    setFormErrors(currentFormErrors);
  };

  const forgotPassword = () => {
    history.push("/forgot-password");
  };

  const signUp = () => {
    history.push(process.env.REACT_APP_CLINIC_URL + "sign-up");
  };

  const login = () => {
    if (user.username == null || user.password == null) {
      if (user.username == null) {
        setFormErrors({
          username: "This field is required",
        });
      }

      if (user.password == null) {
        setFormErrors({
          password: "This field is required",
        });
      }

      if ((user.username == null) & (user.password == null)) {
        setFormErrors({
          username: "This field is required",
          password: "This field is required",
        });
      }
    } else {
      toast({
        message: "Authenticating",
        color: "success",
      });
      setErrorLogin("");
      setAuthenticating(true);
      _signIn(user.username, user.password);
    }
  };

  async function onLogin() {
    setLoader(true);
    try {
      let response = await getQuery(USER_DATA);
      let roles = response.data.getUserInfo.data.roles;
      let userData = {};
      dispatchUser(updateUser({ userData }));
      // if (
      //   response?.data?.getUserInfo.data.subscriptionStatus !==
      //   "authenticated"
      // ) {
      //   alert(
      //     "The subscription payment for this clinic is pending. Please login via Clinic and complete Payment to get access of Dashboard"
      //   );
      //   return;
      // }
      if (roles.filter((role) => role == "Doctor").length > 0) {
        // history.push("/clinic/dashboard");
        sessionStorage.setItem("userRole", roles);
        sessionStorage.setItem(
          "clinic_id",
          response.data.getUserInfo.data.clinicId
        );
        sessionStorage.setItem("loginStatus", true);
        sessionStorage.setItem("showPrompt", true);
        sessionStorage.setItem(
          "doctorVerified",
          response.data.getUserInfo.data.doctorVerified
        );
        sessionStorage.setItem(
          "role-id",
          response?.data?.getUserInfo?.data?.profileId
        );

        const userdata = getMyProfile();
        const { userId } = await getCurrentUser();
        const { email, phone_number } = await fetchUserAttributes();

        dispatchUser(
          updateUser({
            userId,
            userMobile: phone_number,
            userEmail: email,
            loginStatus: true,
            userRoles: roles,
            data: userdata,
          })
        );
      } else {
        alert("You have entered credentials with wrong access.");
      }
    } catch (error) {
      snackbar({
        message: "Some error occurred while trying to login",
        color: "danger",
      });
      console.log("Error: ", error);
    }
    setLoader(false);
  }

  React.useEffect(() => {
    if (globalStateUser.loginStatus) {
      history.push("/dashboard?prompt=true");
    }
  }, [globalStateUser]);

  async function _signIn(username, password) {
    setLoader(true);
    try {
      console.log(username, password);
      await signOut();
      const { isSignedIn, nextStep } = await signIn({ username, password });
      console.log(isSignedIn, nextStep, username, password);
      if (nextStep.signInStep == "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
        // history.push("/change-password");
        setChangeTempPassword(user);
      } else if (
        nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_SMS_CODE" ||
        nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE" ||
        nextStep.signInStep === "CONTINUE_SIGN_IN_WITH_MFA_SELECTION"
      ) {
        if (
          nextStep.signInStep === "CONTINUE_SIGN_IN_WITH_MFA_SELECTION" ||
          nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE"
        )
          setAuthType("TOTP");
        else setAuthType("SMS");
        setCognitoUser(user);
        // history.push("/otp-verification");
      } else {
        if (env !== "dev") {
          let mfa = await checkMFA();
          if (mfa === "NOMFA") {
            setMfa(true);
          } else onLogin();
        } else onLogin();
      }
    } catch (error) {
      console.log(error);
      setAuthenticating(false);
      snackbar({
        message: error.message,
        color: "danger",
      });
    }
    setLoader(false);
  }

  if (mfa) {
    return (
      <>
        <SetMFAasTOTP userData={userData} onSuccess={onLogin} />
      </>
    );
  }

  if (congnitoUser) {
    return (
      <>
        <Loader show={loader} />
        <OtpVerification
          cognitoObject={congnitoUser}
          onSuccess={onLogin}
          mfaType={authType}
        />
      </>
    );
  }

  if (changeTempPassword) {
    return <ChangeTempPassword user={changeTempPassword} />;
  }
  return (
    <Fragment>
      <Loader show={loader} />
      <PDFViewer
        link={tnc_file}
        show={showPDF}
        setShow={setShowPDF}
        fileName="Terms and Conditions for Clinic"
        customButton={
          <>
            <button
              onClick={() => {
                setShowPDF(false);
                setChecked(true);
              }}
              className="docg-save-btn"
            >
              {checked ? "Accepted" : "Accept"}
            </button>
          </>
        }
      />
      <Helmet>
        <title>Login | Doctor</title>
      </Helmet>
      <Modal
        onHide={() => setShowTNC(false)}
        size="lg"
        show={showTNC}
        // onHide={handleNotificationsView}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg" className="ml-3">
            <span className="ModalTitle">Updated Terms and Conditions</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "82vh", overflow: "auto" }}>
          <iframe
            title="pdf"
            src={tnc_file}
            style={{ width: "600px", height: "500px" }}
            frameborder="0"
          ></iframe>
        </Modal.Body>
      </Modal>
      <div className="mbsc-grid">
        <div className="mbsc-card main-card-wrapper">
          <div className="signup-wrapper">
            <div className="mbsc-row">
              <div className="mbsc-col-xl-5 doctor-images-left mbsc-col-12 p-0">
                <div className="left-signup-div"></div>
                <img src={doctor} className="left-signup-img" />
              </div>
              <div className="mbsc-col-xl-7 mbsc-col-12 signup-right">
                <img src={grid} className="grid-top" />
                <img src={signin_circle} className="signin-circle" />
                <div className="bottom-circle" />
                <div className="top-circle" />
                <div className="right-signup-div">
                  <div className="get-started-wrapper">
                    <p className="get-txt">Welcome to Doctor Portal!</p>
                    <p className="connect-txt"></p>
                    {/* <br /> */}
                    {/* <br /> */}
                    <br />
                    <div className="signup-wrapper-inner">
                      <mobiscroll.Input
                        inputStyle="underline"
                        labelStyle="floating"
                        placeholder=""
                        name="username"
                        type="email"
                        onChange={handleChange}
                        valid={!formErrors.username}
                        errorMessage={formErrors.username}
                        disabled={authenticating}
                      >
                        Enter Email Address*
                      </mobiscroll.Input>
                      <br />
                      <mobiscroll.Input
                        inputStyle="underline"
                        labelStyle="floating"
                        passwordToggle={true}
                        type="password"
                        name="password"
                        onChange={handleChange}
                        valid={!formErrors.password}
                        errorMessage={formErrors.password}
                        // disabled={authenticating}
                        className="input-style"
                        style={{ marginBottom: "10px" }}
                      >
                        Password*
                      </mobiscroll.Input>
                      <br />
                      <button
                        className="transparent-button"
                        onClick={forgotPassword}
                      >
                        Forgot Password?
                      </button>
                      <br />
                      <div className="d-flex w-full align-items-center justify-content-center">
                        <div className="custom-repeat-checkbox-cont mr-n4">
                          <Checkbox
                            value="tnc"
                            checked={checked}
                            onChange={(e) => {
                              setChecked((prev) => !prev);
                              setShowPDF(true);
                            }}
                          ></Checkbox>
                        </div>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#6c5b7b",
                            fontFamily: "Poppins",
                          }}
                        >
                          Terms and conditions
                        </span>
                      </div>
                      <button
                        className="pink-button"
                        onClick={login}
                        disabled={authenticating || !checked}
                      >
                        <img src={login_icon} />
                        &nbsp;&nbsp; Login
                      </button>
                      <br />

                      <p className="text-danger text-center">{errorLogin}</p>
                      <center>
                        <p className="signup-link">
                          Signin as a:
                          <span>
                            <button
                              className="transparent-button"
                              id="signup-btn-small"
                              onClick={() => {
                                window.open(url, "_blank");
                              }}
                            >
                              Clinic
                            </button>
                          </span>
                        </p>
                      </center>
                    </div>
                  </div>
                </div>
                <img
                  src={line}
                  className="lines-bottom"
                  style={{ marginLeft: "8%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SignIn;
