import { Switch, snackbar } from "@mobiscroll/react";
import { Select } from "@mobiscroll/react5";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import _debounce from "lodash/debounce";
import React, { Fragment, useCallback, useState } from "react";
import { Button, Card, Col, Image, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Loader from "src/components/Dashboard/common/Loader";
import AllPatient from "../../../assets/icons/All Patient Record.svg";
import ActionCellRender from "../Layouts/ActionCellRender";
import ExportCSV from "../common/ExportCSV";
import "./PatientList.css";
import PatientNameCellRenderer from "./PatientRenderer";
import { listPatients } from "./api";
import { isMobileOnly } from "react-device-detect";
const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;

const options = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

function PatientList() {
  const history = useHistory();

  // eslint-disable-next-line
  const [gridApi, setGridApi] = useState(null);
  const [displayRecords, setDisplayRecords] = useState("2147483647");
  const [totalRecords, setTotalRecords] = useState(0);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [listData, setListData] = useState([]);
  const [data, setData] = useState(["00"]);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = React.useState("");
  const [fileName, setFileName] = React.useState("Patient_list");
  const [csvData, setCSVData] = React.useState([]);

  const searchPatients = async (search) => {
    listDoctorPatients(displayRecords, search);
  };

  React.useEffect(() => {
    console.log(listData);
    setCSVData(
      listData.map(
        ({ firstName, createdAt, lastName, phoneNumber, email, lastVisit }) => {
          return {
            "First Name": firstName,
            "Last Name": lastName,
            "Phone number": phoneNumber,
            Email: email,
            "Created at": new Date(createdAt).toLocaleString(),
            "Last visit": new Date(lastVisit).toLocaleString(),
          };
        }
      )
    );
  }, [listData]);

  const debounceFn = useCallback(_debounce(searchPatients, 1000), []);
  React.useEffect(() => {
    debounceFn(value);
  }, [value]);

  const listDoctorPatients = React.useCallback(
    async (displayRecords, search) => {
      setLoader(true);
      try {
        let _displayRecords = displayRecords;

        const res = await listPatients(+_displayRecords, search);
        if (res?.data) {
          setListData(res.data);
          let value =
            res?.data.length < 10 ? `0${res?.data.length}` : res.data.length;
          setData((prevProps) => {
            let arr = [];
            for (let index = 0; index <= res.totalCount; index++) {
              let value = index < 10 ? `0${index}` : index;
              arr.push(value);
            }
            if (res.totalCount < arr[arr.length - 1] || res.totalCount < 5) {
              let value =
                res.totalCount < 10 ? `0${res.totalCount}` : res.totalCount;
              arr.push(value);
            }
            return arr;
          });
          setDisplayRecords(value);
          setTotalRecords(res.totalCount);
        }
      } catch {
        setListData([]);
        snackbar({
          message: "Error while getting patient list. Please try again later.",
          color: "danger",
        });
      }
      setLoader(false);
    },
    [displayRecords, listPatients]
  );

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onQuickFilterChanged = (e) => {
    setValue(e.target.value);
  };

  const onPageSizeChanged = async (e) => {
    if (e.valueText != displayRecords) {
      listDoctorPatients(e.valueText, value);
    }
    setDisplayRecords(e.valueText);
  };
  const routeChange = () => {
    history.push("add-patient");
  };

  React.useEffect(() => {
    listDoctorPatients(displayRecords, value);
  }, []);

  const defaultColDef = {
    sortable: true,
    // resizable: false,
    filter: true,
    flex: 1,
    minWidth: 200,
  };
  // if we had column groups, we could provide default group items here
  const defaultColGroupDef = {};

  function StatusCellRenderer(params) {
    return (
      <div className="w-100 d-flex align-items-center justify-content-center">
        <Switch
          defaultChecked={params.value}
          theme="auto"
          position="start"
          color="success"
          checked={params.value}
          name="noti2"
          id={`patient-${params.rowIndex}`}
        />
      </div>
    );
  }

  const patientNameValueGetter = (params) => {
    return {
      name: params.data.firstName + " " + params.data.lastName,
      status: "High",
      patientId: params.data.id,
    };
  };

  // const renderAppointments = async () => {
  //   const t_start = new Date();
  //   const t_end = new Date(t_start);
  //   t_end.setTime(t_start.getTime() + 24 * 60 * 60 * 1000);
  //   const res = await getQuery(
  //     LIST_TODAY_APPOINTMENTS(
  //       5,
  //       t_start.toISOString(),
  //       t_end.toISOString(),
  //       "status=Booked,In-Progress"
  //     )
  //   );

  //   console.log(res);

  //   return `start time ${t_start.toISOString()} end timne ${t_end.toISOString()} \n\n ${JSON.stringify(
  //     res?.data
  //   )}`;
  // };

  return (
    <Fragment>
      <Loader show={loader} />
      {/* {renderAppointments()} */}
      {/* <TodoListAppointment /> */}
      <div className="mbsc-col-12">
        <Card className="docg-card docg-appointment patient-wrapper card-wrapper m-0">
          <CardHeader className="d-flex justify-content-between cardHeaderUsed">
            <CardTitle className="cardTitleUsed">
              <Image src={AllPatient} alt="" className="mr-2" />{" "}
              {isMobileOnly ? "Patients" : "All PatientRecords"}
            </CardTitle>
            <div style={{ display: "flex", alignItems: "center" }}>
              {!isMobileOnly && (
                <div className="docg-table-search">
                  <input
                    type="text"
                    onChange={onQuickFilterChanged}
                    value={value}
                    id="quickFilter"
                    placeholder="Search..."
                  />
                </div>
              )}
              <ExportCSV csvData={csvData} fileName={fileName} />
              <button className="docg-save-btn" onClick={routeChange}>
                Add
              </button>
            </div>
          </CardHeader>
          <CardBody className="docg-card-body docg-h-standard pb-4">
            {/* <div className="records-display-new">
              <span>Showing&nbsp;</span>
              <label className="record-selection-dropdown" htmlFor="">
                {totalRecords >= 0 && (
                  <Select
                    touchUi={false}
                    onChange={onPageSizeChanged}
                    value={displayRecords}
                    data={data}
                  />
                )}
              </label>
              <span>&nbsp;Records</span>
            </div> */}
            <Row>
              <Col>
                <div
                  className="ag-theme-alpine"
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <AgGridReact
                    rowData={listData}
                    defaultColDef={defaultColDef}
                    frameworkComponents={{
                      actionCellRender: ActionCellRender,
                      statusCellRenderer: StatusCellRenderer,
                      patientNameCellRenderer: PatientNameCellRenderer,
                    }}
                    defaultColGroupDef={defaultColGroupDef}
                    enableRangeSelection={true}
                    onGridReady={onGridReady}
                    domLayout={"autoHeight"}
                    animateRows={true}
                    paginationPageSize={10}
                    pagination={true}
                  >
                    <AgGridColumn
                      headerName="Patient Name"
                      headerTooltip="Patient Name"
                      field="PatientName"
                      tooltipField="PatientName"
                      valueGetter={patientNameValueGetter}
                      initialSort="asc"
                      cellRenderer="patientNameCellRenderer"
                    ></AgGridColumn>

                    <AgGridColumn
                      headerName="Diagnosis"
                      headerTooltip="Diagnosis"
                      field="diagnosis"
                      tooltipField="diagnosis"
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Last Visit"
                      headerTooltip="Last Visit"
                      field="lastVisit"
                      valueFormatter={(params) => {
                        if (params.value)
                          return new Date(params.value).toLocaleDateString(
                            "en-US",
                            options
                          );
                        return "-";
                      }}
                      tooltipValueGetter={(params) => {
                        if (params.value)
                          return new Date(params.value).toLocaleDateString(
                            "en-US",
                            options
                          );
                        return "-";
                      }}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Follow Up"
                      headerTooltip="Follow Up"
                      field="followUp"
                      valueFormatter={(params) => {
                        if (params.value)
                          return new Date(params.value).toLocaleDateString(
                            "en-US",
                            options
                          );
                        return "-";
                      }}
                      tooltipValueGetter={(params) => {
                        if (params.value)
                          return new Date(params.value).toLocaleDateString(
                            "en-US",
                            options
                          );
                        return "-";
                      }}
                    ></AgGridColumn>

                    <AgGridColumn
                      headerName="Email"
                      headerTooltip="Email"
                      field="email"
                      minWidth="300"
                      tooltipField="email"
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Phone Number"
                      headerTooltip="Phone Number"
                      field="phoneNumber"
                      tooltipField="phoneNumber"
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName=""
                      field="isActive"
                      cellRenderer="statusCellRenderer"
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </Fragment>
  );
}

export default PatientList;
