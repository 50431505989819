import { gql } from "@apollo/client";

export const CHECK_CLINIC_STATUS = gql`
  query MyQuery($email: AWSEmail!) {
    checkClinicExist(email: $email) {
      message
      statusCode
    }
  }
`;

export const GET_CLINIC_ID = gql`
  query MyQuery {
    listClinics {
      data {
        logoLink
        id
      }
    }
  }
`;

export const UPLOAD_CLINIC_CONTRACT = gql`
  query MyQuery($requestId: String!) {
    uploadClinicContract(requestId: $requestId) {
      data {
        name
        url
      }
      message
      statusCode
    }
  }
`;

export const UPLOAD_CANCELLED_CHECK = gql`
  query MyQuery($requestId: String!) {
    uploadCancelledCheque(requestId: $requestId) {
      message
      statusCode
      data {
        url
      }
    }
  }
`;

export const USER_DATA = gql`
  query MyQuery {
    getUserInfo {
      message
      statusCode
      data {
        profileId
        clinicId
        roles
        doctorVerified
        subscriptionStatus
      }
    }
  }
`;

export const LIST_PATIENTS = gql`
  query MyQuery {
    listPatients(take: 100) {
      data {
        accountId
        id
        dateOfBirth
        firstName
        lastName
      }
    }
  }
`;

export const LIST_CLINIC_REQUEST = gql`
  query MyQuery {
    listClinicCreationRequests(
      take: 200
      orderBy: "createdAt=-1"
      ilters: "approvalStatus=Pending"
    ) {
      data {
        clinicAddress
        clinicName
        email
        firstName
        id
        lastName
        phoneNumber
        title
      }
    }
  }
`;
export const GET_DOCTOR_SCHEDULES = gql`
  query MyQuery($data: ListSchedulesRequest!) {
    listSchedules(data: $data) {
      data {
        duration
        end
        gap
        id
        relatedId
        doctorId
        recurrence {
          interval
          repeat
          until
          weekDays
        }
        start
      }
      statusCode
    }
  }
`;

export const GET_DOCTOR_ID = gql`
  query MyQuery {
    getMyProfile {
      data {
        id
      }
      message
      statusCode
    }
  }
`;

export const LIST_TIME_SLOTS = gql`
  query MyQuery($data: ListTimeslotsRequest!) {
    listTimeslots(data: $data) {
      message
      statusCode
      data {
        end
        groupId
        id
        appointmentId
        scheduleId
        doctorId
        start
        status
        patient {
          email
          phoneNumber
          firstName
          lastName
          profilePicture
          id
        }
      }
    }
  }
`;

export const PROFILE_DETAILS = gql`
  query MyQuery {
    getMyProfile {
      message
      statusCode
      data {
        address
        district
        pinCode
        state
        award
        email
        firstName
        lastName
        middleName
        phoneNumber
        profilePicture
        signature
        specialization
        specializationsDescription
        title
        yearsOfExperience
      }
    }
  }
`;

export const DOCTOR_DETAILS = gql`
  query MyQuery {
    getMyProfile {
      data {
        doctorProfile {
          biography
          hospitalAffiliations
          medicalSchool
          residencies
        }
        degree
        certifications
        gender
        yearsOfExperience
        award
      }
    }
  }
`;

export const LIST_PATIENT_RECORDS = (displayRecords, search) => gql`
  query MyQuery {
    listDoctorPatients(take: ${displayRecords}, search: "${search}", orderBy:"lastVisit=-1") {
      totalCount
      data {
        firstName
        lastName
        profilePicture
        diagnosis
        lastVisit
        isActive
        followUp
        createdAt
         email
      phoneNumber
        id
        referredDoctor {
          firstName
          lastName
        }
      }
    }
  }
`;

export const LIST_DOCTOR_FEE = gql`
  query MyQuery($data: ID!) {
    listDoctorFee(doctorId: $data, take: 2147483647, orderBy: "createdAt=-1") {
      data {
        allowedFreeFollowUpVisits
        cancelationIn24Hours
        cancellationAllowed
        cashPaymentAccepted
        doctorId
        fee
        followUpExpiry
        followUpExpiryUnits
        id
        planName
        planType
        policy
        reschedulingAllowed
        reschedulingUnits
        status
      }
      message
      totalCount
    }
  }
`;

export const LIST_DOCTOR_MEMBERSHIP_PLANS = gql`
  query MyQuery {
    listDoctorMembershipPlans(take: 1000) {
      data {
        description
        id
        name
        numberOfMembers
        numberOfVisits
        paymentFrequency
        price
        status
        uploadedFile
        validityTime
        validityTimeUnit
      }
      message
      statusCode
    }
  }
`;

export const GET_UPLOAD_POLICY_LINK = gql`
  query MyQuery($data: UploadDoctorFeePolicyRequest!) {
    uploadDoctorFeePolicy(data: $data) {
      message
      statusCode
      data {
        url
        name
      }
    }
  }
`;

export const GET_PATIENT = gql`
  query MyQuery($data: GetPatientRequest!) {
    getPatient(data: $data) {
      data {
        patientGrowthReport {
          heights {
            updatedAt
            height
            unit
          }
          weights {
            weight
            unit
            updatedAt
          }
        }
        profilePicture
        dateOfBirth
        postalCode
        street
        state
        city
        phoneNumber
        email
        bloodGroup
        age
        socialHistory
        title
        weight
        height
        firstName
        lastName
        gender
        id
        insuranceNumber
        insuranceCompanyName
      }
      message
      statusCode
    }
  }
`;

export const GET_UPLOAD_MEMBERSHIP_FILE_LINK = gql`
  query MyQuery($data: UploadMPImageRequest!) {
    uploadMPImageUrl(data: $data) {
      message
      statusCode
      data {
        url
        name
      }
    }
  }
`;

export const GET_TODO_LIST = (take) => gql`
  query MyQuery {
    listToDoNotes(orderBy: "date=-1", take:${take}) {
      data {
        date
        doctorId
        id
        name
        priority
        status
      }
      message
      statusCode
      totalCount
    }
  }
`;
export const GET_AFFECTED_PATIENTS_BY_GROUP = gql`
  query MyQuery($data: getAffectedPatientsByGroup!) {
    getAffectedPatientsByGroup(data: $data) {
      data {
        phoneNumber
        lastName
        firstName
        email
      }
      message
      statusCode
    }
  }
`;

export const LIST_PATIENTS_CALENDAR = (search = "", take = 20) => gql`
  query MyQuery {
    listClinicPatientsAll(take: ${take}, search:"${search}") {
      data {
        id
        profilePicture
        firstName
        lastName
      }
    }
  }
`;

export const LIST_TODAY_APPOINTMENTS = (take, start, end, filters) => gql`
  query MyQuery {
    listAppointmentsDoctor(take: ${take}, start:"${start}", end: "${end}", filters:"${filters}",orderBy: "start=1") {
      data {
        id
        doctorId
        paymentMode
        newPatient
        patient {
          firstName
          lastName
          profilePicture
          id
        }
        consultationType
        start
        end
        patientDelay
        patientStatus
        status
        note {
          patientComplaints {
          updatedAt
          text
        } 
        }
      }
        totalCount

    }
  }
`;

export const TOTAL_APPOINTMENTS_COUNT = (filters) => gql`
  query MyQuery {
    listAppointmentsDoctor(take: 2147483647, filters:"${filters}", orderBy:"start=1") {
        data{
          start
        }
        totalCount
    }
  }
`;
export const LIST_APPOINTMENTS_SCHEDULE = (filters, start) => gql`
  query MyQuery {
    listAppointmentsDoctor(take:100000000, filters:"${filters}", start:"${start}") {
      data {
      status
      start
      end
      timeslotId
      id
      patient {
        email
        phoneNumber
        firstName
        lastName
        profilePicture
        id
      }
    }
    }
  }
`;

// export const LIST_APPOINTMENT
export const GET_APPOINTMENT_PRESCIRPTION = gql`
  query MyQuery($data: GetAppointmentRequest!) {
    getAppointment(data: $data) {
      data {
        prescription {
          drugs {
            additionalAdvice
            composition
            dose
            drugName
            drugType
            duration
            gapBetweenDosage
            gapBetweenDosageType
            id
            doseFrequency
            instruction
          }
          eyePrescription {
            leftEye {
              distance {
                axis
                cyl
                spl
                va
              }
              near {
                axis
                cyl
                spl
                va
              }
              pg {
                axis
                cyl
                spl
                va
              }
            }
            rightEye {
              near {
                axis
                cyl
                spl
                va
              }
              distance {
                axis
                cyl
                spl
                va
              }
              pg {
                axis
                cyl
                spl
                va
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_APPOINTMENT_LAB_TEST = gql`
  query MyQuery($data: GetAppointmentRequest!) {
    getAppointment(data: $data) {
      data {
        tests {
          id
          instructions
          optionalInstructions
          testName
        }
      }
    }
  }
`;

export const GET_PATIENT_ALLERGIES = gql`
  query MyQuery($data: GetPatientRequest!) {
    getPatient(data: $data) {
      data {
        allergies {
          severity
          reaction
          name
          allergyType
        }
      }
      statusCode
    }
  }
`;

export const LIST_MEDICINES = gql`
  query MyQuery($composition: String!, $name: String!) {
    listMedicine(composition: $composition, name: $name, take: 10) {
      data {
        name
        composition
        id
        manufacturer
        packingType
      }
      totalCount
    }
  }
`;

export const LIST_DIAGNOSIS_TEMPLATE = gql`
  query MyQuery($search: String!) {
    listDiagnosisTemplates(search: $search) {
      data {
        diagnosisName
      }
      message
      statusCode
    }
  }
`;

export const LIST_PROCEDURE_TEMPLATE = gql`
  query MyQuery($search: String!) {
    listProcedureTableTemplates(search: $search) {
      data {
        procedureName
      }
      message
      statusCode
    }
  }
`;

export const LIST_INSTRUCTION_TEMPLATE = gql`
  query MyQuery($search: String!) {
    listInstructionTemplates(search: $search) {
      data {
        instruction
      }
      statusCode
      message
    }
  }
`;

export const LIST_PLANOFCARE_TEMPLATE = (search) => gql`
  query MyQuery {
    listPlanOfCareTemplates(search: "${search}") {
      data {
        planOfCare
      }
      statusCode
      message
    }
  }
`;

export const LIST_PROGNOSIS_TEMPLATE = gql`
  query MyQuery($search: String!) {
    listPrognosisTemplates(search: $search) {
      data {
        prognosis
      }
      statusCode
      message
    }
  }
`;
export const LIST_NUTRIADV_TEMPLATE = gql`
  query MyQuery($search: String!) {
    listNutritionalAdviceTemplates(search: $search) {
      data {
        nutritionalAdvice
      }
      statusCode
      message
    }
  }
`;
export const LIST_IMMU_TEMPLATE = gql`
  query MyQuery($search: String!) {
    listImmunizationStatusTemplates(search: $search) {
      data {
        immunizationStatus
      }
      statusCode
      message
    }
  }
`;

export const GET_NOTES = gql`
  query MyQuery($data: GetAppointmentRequest!) {
    getAppointment(data: $data) {
      data {
        note {
          diagnosis {
            diagnosisName
            message
          }
          patientComplaints {
            text
            updatedAt
            updatedBy {
              firstName
              lastName
            }
          }
          procedure {
            message
            procedureName
          }
          planOfCare
          additionalAdvice
          bloodPressure
          bodyTemp
          height
          heightUnits
          id
          immunizationStatus
          instruction
          nutritionalAdvice
          observation
          prognosis
          respRate
          pulseRate
          tempUnits
          visionTable {
            autoRefrectometer {
              label
              leftEye {
                axis
                cyl
                spl
              }
              rightEye {
                axis
                cyl
                spl
              }
            }
            eyePressure {
              iop {
                od
                os
              }
              method
              pachy {
                od
                os
              }
              time
            }
            vision {
              distance {
                withGlass {
                  od
                  os
                }
                unaided {
                  od
                  os
                }
                withPHole {
                  od
                  os
                }
              }
              near {
                unaided {
                  od
                  os
                }
                withGlass {
                  od
                  os
                }
                withPHole {
                  od
                  os
                }
              }
            }
          }
          visionTableExists
          weight
          weightUnits
        }
      }
      message
      statusCode
    }
  }
`;

export const LIST_PRESCRIPTION_TEMPLATES = gql`
  query MyQuery($clinicId: String!, $search: String!, $filters: String!) {
    listDiagnosisPrescriptionTemplates(
      clinicId: $clinicId
      search: $search
      filters: $filters
    ) {
      data {
        diagnosisTemplate
        prescriptionTemplateName
        drugs {
          additionalAdvice
          composition
          doseFrequency
          dose
          drugName
          drugType
          duration
          gapBetweenDosage
          gapBetweenDosageType
          id
          instruction
          period
        }
      }
      message
      statusCode
      totalCount
    }
  }
`;

export const GET_TOTAL_BILLINGS = gql`
  query MyQuery($data: GetTotalBillingsOfDoctorRequest!) {
    getTotalBillingsOfDoctor(data: $data) {
      data {
        totalBillings
      }
    }
  }
`;

export const GET_TOTAL_BILLING_GROWTH = (data, start) => gql`
  query MyQuery(
    data: $data, start:"$start"
  ) {
    getTotalBillingsOfDoctor(data: $data) {
      data {
        totalBillings
      }
    }
  }
`;
export const LIST_INVOICES = (take, filters) => gql`
  query MyQuery {
    listInvoices(take: ${take}, filters: "${filters}", orderBy: "createdAt=-1") {
      data {
      patientId
      patientProfilePicture
      to
      createdAt
      amount
      status
      paymentMethod
      reason
      id
      uhid
    }
      message
      statusCode
      totalCount
    }
  }
`;

export const GET_ACTIVE_POLICIES = gql`
  query MyQuery {
    getMyProfile {
      data {
        doctorProfile {
          policy {
            Emergency {
              status
            }
            Face2Face {
              status
              cashPaymentAccepted
            }
            Online {
              status
            }
          }
        }
      }
    }
  }
`;

export const GET_INVOICE = gql`
  query MyQuery($data: GetInvoiceRequest!) {
    getInvoice(data: $data) {
      data {
        PDFFile
        amount
        to
        id
        paymentMethod
        patientEmail
        patientPhoneNumber
        doctorEmail
        toAddress
        createdAt
        status
        items {
          amount
          cgst
          description
          quantity
          serialNumber
          sgst
          type
        }
        doctorName
        doctorPhoneNumber
        signature
        fromAddress
      }
      message
      statusCode
    }
  }
`;

export const GET_TOTAL_GROWTH_BILLINGS = (start) => gql`
  query MyQuery($data : GetTotalBillingsOfDoctorRequest!) {
    getTotalBillingsGrowthOfDoctor(
      data:$data,
      start: "${start}"
    ) {
      data {
        totalBillingsGrowthOfDoctor
      }
      message
      statusCode
    }
  }
`;
export const GET_NUMBER_OF_PATIENTS = gql`
  query MyQuery($data: GetNumberOfPatientsRequest!) {
    getNumberOfPatients(data: $data) {
      data {
        numberOfPatients
      }
      message
      statusCode
    }
  }
`;
export const GET_NUMBER_OF_PATIENTS_GROWTH = (start) => gql`
  query MyQuery($data: GetNumberOfPatientsRequest!) {
    getNumberOfPatientsGrowth(data: $data,
      start: "${start}"
    ) {
      data {
        numberOfPatientsGrowth
      }
      message
      statusCode
    }
  }
`;

export const GET_PROFILE = gql`
  query MyQuery {
    getMyProfile {
      data {
        yearsOfExperience
        award
        clinicName
        address
        district
        state
        pinCode
        title
        middleName
        lastName
        firstName
        specialization
        specializationsDescription
        designation
        email
        phoneNumber
        profilePicture
        signature
        doctorProfile {
          biography
          hospitalAffiliations
          medicalSchool
          residencies
        }
        degree
        certifications
        gender
        id
      }
      message
      statusCode
    }
  }
`;

export const PRESCRIPTION_DOWNLOAD_PDF = (
  appointmentId,
  doctorId,
  patientId,
  clinicId
) => gql`
query PrescriptionDownloadPDF {
  getAppointment(data: {id: "${appointmentId}"}) {
    data {
      prescription {
        doctorId
        id
        patientId
        drugs {
          additionalAdvice
          composition
          dose
          drugName
          doseFrequency
          drugType
          duration
          gapBetweenDosage
          gapBetweenDosageType
          id
          instruction
          period
        }
        eyePrescription {
          leftEye {
            distance {
              axis
              cyl
              spl
              va
            }
            near {
              axis
              cyl
              spl
              va
            }
            pg {
              axis
              cyl
              spl
              va
            }
          }
          rightEye {
            distance {
              axis
              cyl
              spl
              va
            }
            near {
              axis
              cyl
              spl
              va
            }
            pg {
              axis
              cyl
              spl
              va
            }
          }
        }
      }
      note {
        additionalAdvice
        bloodPressure
        bodyTemp
        createdAt
        diagnosis {
          diagnosisName
          message
        }
        height
        id
        observation
        patientComplaints {
          text
          updatedAt
        }
        pulseRate
        respRate
        tempUnits
        weight
        weightUnits
        immunizationStatus
        nutritionalAdvice
        instruction
        planOfCare
        procedure {
          message
          procedureName
        }
        prognosis
        visionTable {
          eyePressure {
            iop {
              od
              os
            }
            method
            pachy {
              od
              os
            }
            time
          }
          vision {
            distance {
              unaided {
                od
                os
              }
              withGlass {
                od
                os
              }
              withPHole {
                od
                os
              }
            }
            near {
              unaided {
                od
                os
              }
              withGlass {
                od
                os
              }
              withPHole {
                od
                os
              }
            }
          }
        }
        visionTableExists
      }
      invoices
      tests {
        id
        instructions
        optionalInstructions
        testName
      }
      consultationType
      delay
      doctorApprovement
      doctorId
      emergencyAppointment
      end
      id
      patientCheckedIn
      patientId
      payment {
        orderNumber
        verified
      }
      paymentMode
      price
      reasonForCancellation
      start
      status
      timeslotId
      survey {
        questions {
          id
          mediaDescription
          question
          textDescription
        }
      }
      eyePrescriptionExists
    }
  }
  getDoctor(data: {id: "${doctorId}"}) {
    data {
      doctorProfile {
        paymentPreference
        biography
        policy {
          Online {
            fee
            policy
            reschedulingAllowed
            cashPaymentAccepted
            cancelationIn24Hours
            cancellationAllowed
            planName
            planType
            reschedulingUnits
            followUpExpiryUnits
            followUpExpiry
            allowedFreeFollowUpVisits
          }
          Face2Face {
            fee
            policy
            reschedulingAllowed
            cashPaymentAccepted
            cancelationIn24Hours
            cancellationAllowed
            planName
            planType
            reschedulingUnits
            followUpExpiryUnits
            followUpExpiry
            allowedFreeFollowUpVisits
          }
        }
        reviews {
          comment
          createdAt
          rating
          id
        }
        averageRating
      }
      title
      address
      degree
      firstName
      lastName
      middleName
      specialization
      associatedClinics
      yearsOfExperience
      training
      profilePicture
      signature
      phoneNumber
      email
      medicalRegistrationNumber
    }
    statusCode
  }
  getPatient(data: {id: "${patientId}"}) {
    data {
      dateOfBirth
      email
      firstName
      gender
      phoneNumber
      street
      state
      lastName
      postalCode
      city
    }
  }
  getClinicProfile(data: {id: "${clinicId}"}) {
    data {
      about
      address
      contactDetails
      district
      logoLink
      mail
      name
      pinCode
      registrationNumber
      clinicLogoHeader

    }
  }
}`;

export const LIST_SPECIALIZATIONS = (search) => gql`
  query MyQuery {
    listSpecializations (search: "${search}", orderBy: "name=1", take:2147483647) {
      data {
        name
      }
    }
  }
`;

export const LIST_PATIENT_HISTORY = (patientId, start, end, filters) => gql`
  query MyQuery {
    listPatientHistory(patientId: "${patientId}", take:2147483647, start:"${start}", end:"${end}", orderBy:"start=-1", filters: "${filters}") {
     data {
      appointmentDetails {
        paymentMode
        id
        prescription {
          drugs {
            id
            drugName
          }
        }
        note{
          diagnosis{
            diagnosisName
            message
          }
        }
        tests {
          testName
        }
        start
        status
      }
      doctorName
    }
    statusCode
  }
}
`;

export const LIST_PATIENT_SYMPTOMS = (patientId) => gql`
  query MyQuery {
    getAppointment(data: { id: "${patientId}" }) {
      data {
        start
        survey {
          questions {
            id
            mediaDescription
            question
            textDescription
          }
        }
      }
      statusCode
    }
  }
`;

export const CHECK_CONSULTATION = gql`
  query MyQuery($data: GetAppointmentRequest!) {
    getAppointment(data: $data) {
      data {
        status
        paymentMode
        consultationType
        start
        id
        end
        invoices
      }
      message
      statusCode
    }
  }
`;

export const LIST_ALL_NOTIFICATIONS = gql`
  query MyQuery {
    listAllNotifications(userType: Doctor, orderBy: "createdAt=-1", take: 100) {
      data {
        metadata {
          ... on LabReportUploadNotification {
            notificationType
            appointmentId
            doctorId
            labReportFileName
            labReportUploadedDate
            labTestReports
            patientId
            patientName
            patientProfilePicture
            appointmentDate
          }
          ... on EmergencyAppointmentRequestNotification {
            notificationType
            appointmentId
            status
            patientSymptoms {
              questions {
                textDescription
                question
                mediaDescription
                id
              }
            }
            patientProfilePicture
            patientName
            patientId
            doctorId
            appointmentDate
          }

          ... on BookAppointmentNotification {
            notificationType
            appointmentId
            appointmentDate
            consultationType
            doctorId
            doctorName
            patientId
            patientName
          }
          ... on PatientCancelAppointmentNotification {
            notificationType
            appointmentId
            appointmentDate
            consultationType
            doctorId
            patientId
            patientName
          }
          ... on ReferPatientToNotification {
            notificationType
            referredDoctorId
            referredDoctorName
            patientName
            patientId
            doctorName
            doctorId
          }
        }
        id
        acknowledged
        createdAt
      }
      statusCode
      message
    }
  }
`;

export const GET_APPOINTMENT_DETAILS = gql`
  query MyQuery($data: GetAppointmentRequest!) {
    getAppointment(data: $data) {
      data {
        prescription {
          doctorId
          id
          patientId
          drugs {
            additionalAdvice
            composition
            dose
            drugName
            drugType
            duration
            gapBetweenDosage
            gapBetweenDosageType
            id
            instruction
            period
          }
          eyePrescription {
            leftEye {
              distance {
                axis
                cyl
                spl
                va
              }
              near {
                axis
                cyl
                spl
                va
              }
              pg {
                axis
                cyl
                spl
                va
              }
            }
            rightEye {
              distance {
                axis
                cyl
                spl
                va
              }
              near {
                axis
                cyl
                spl
                va
              }
              pg {
                axis
                cyl
                spl
                va
              }
            }
          }
        }
        note {
          additionalAdvice
          bloodPressure
          bodyTemp
          createdAt
          diagnosis {
            diagnosisName
            message
          }
          height
          id
          observation
          patientComplaints {
            text
            updatedAt
            updatedBy {
              firstName
              lastName
              profilePicture
              role
            }
          }
          pulseRate
          respRate
          tempUnits
          weight
          weightUnits
          immunizationStatus
          nutritionalAdvice
          instruction
          planOfCare
          procedure {
            message
            procedureName
          }
          prognosis
          visionTable {
            autoRefrectometer {
              leftEye {
                axis
                cyl
                spl
              }
              rightEye {
                axis
                cyl
                spl
              }
              label
            }
            eyePressure {
              iop {
                od
                os
              }
              method
              pachy {
                od
                os
              }
              time
            }
            vision {
              distance {
                unaided {
                  od
                  os
                }
                withGlass {
                  od
                  os
                }
                withPHole {
                  od
                  os
                }
              }
              near {
                unaided {
                  od
                  os
                }
                withGlass {
                  od
                  os
                }
                withPHole {
                  od
                  os
                }
              }
            }
          }
          visionTableExists
        }
        invoices
        tests {
          id
          instructions
          optionalInstructions
          testName
        }
        labTestReports
        consultationType
        delay
        doctorApprovement
        doctorId
        emergencyAppointment
        end
        id
        patientCheckedIn
        patientId
        payment {
          orderNumber
          verified
        }
        paymentMode
        price
        reasonForCancellation
        start
        status
        timeslotId
        survey {
          questions {
            id
            mediaDescription
            question
            textDescription
          }
        }
        eyePrescriptionExists
      }
      message
      statusCode
    }
  }
`;

export const LIST_LAB_TEST_TEMPLATES = gql`
  query MyQuery($clinicId: String!, $search: String!, $filters: String!) {
    listDiagnosisLabTestTemplates(
      clinicId: $clinicId
      search: $search
      filters: $filters
    ) {
      data {
        diagnosisTemplate
        templateName
        tests {
          id
          instructions
          optionalInstructions
          testName
        }
      }
      message
      statusCode
      totalCount
    }
  }
`;

export const GET_INVOICE_PDF = gql`
  query MyQuery($data: GetInvoiceRequest!) {
    getInvoice(data: $data) {
      data {
        PDFFile
      }
      message
      statusCode
    }
  }
`;

export const LIST_DOCTORS = gql`
  query MyQuery {
    listDoctors(take: 2147483647) {
      data {
        firstName
        lastName
        middleName
        email
        id
      }
      statusCode
    }
  }
`;

export const LIST_VACCINATION_TEMPLATES = (search) => gql`
  query MyQuery {
    listVaccinationTemplates(take: 15, search: "${search}") {
      data {
        vaccinationName
        site
        route
        purpose
        dose
        age
        ageUnit
        id
      }
      statusCode
    }
  }
`;

export const LIST_PATIENT_VACCINATIONS = (patientId) => gql`
  query MyQuery {
    listPatientVaccinationDetails(patientId: "${patientId}", take:2147483647, orderBy:"dueDate=1") {
      data {
        vaccinationName
        status
        id
        completedDate
        dose
        dueDate
        brandName
      }
      statusCode
    }
  }
`;

export const GET_BILLINGS = gql`
  query MyQuery($data: GetTotalBillingsOfDoctorRequest!) {
    getTotalBillingsOfDoctor(data: $data) {
      data {
        totalBillings
      }
      statusCode
      message
    }
  }
`;

export const LIST_DOCTOR_REVIEWS = gql`
  query MyQuery($take: Int = 2147483647) {
    listDoctorReviews(take: $take, orderBy: "createdAt=-1") {
      data {
        rating
        comment
        patientId
        patient {
          profilePicture
          lastName
          firstName
        }
      }
      totalCount
    }
  }
`;
//max int 2147483647
export const DOES_PATIENT_HISTORY_EXISTS = gql`
  query MyQuery($data: String!) {
    listPatientHistory(patientId: $data, filters: "status=Completed") {
      data {
        id
      }
      statusCode
    }
  }
`;

export const LIST_EYE_OBSERVATION_TEMPLATE = gql`
  query MyQuery {
    listEyeObservationTemplate(take: 2147483647) {
      data {
        column1
        column2
        id
      }
    }
  }
`;

//invoice pdf
export const GET_INVOICE_DATA = (id) => gql`
query MyQuery {
  getInvoice(data: {id: "${id}"}) {
    data {
      patientEmail
      amount
      appointmentId
      createdAt
      doctorEmail
      doctorId
      doctorName
      doctorPhoneNumber
      doctorProfilePicture
      fromAddress
      id
      uhid
      items {
        amount
        cgst
        description
        quantity
        serialNumber
        sgst
        type
      }
      patientProfilePicture
      paymentMethod
      reason
      signature
      status
      to
      toAddress
      patientId
    }
    message
    statusCode
  }
}
`;

export const INVOICE_PDF_DOWNLOAD_DATA = (
  appointmentId,
  doctorId,
  patientId,
  clinicId
) => gql`
query PrescriptionDownloadPDF {
  getAppointment(data: {id: "${appointmentId}"}) {
    data {
      consultationType
      start
    }
  }
  getDoctor(data: {id: "${doctorId}"}) {
    data {
      firstName
      lastName
      signature
      phoneNumber
      email
      medicalRegistrationNumber
    }
    statusCode
  }
  getPatient(data: {id: "${patientId}"}) {
    data {
      dateOfBirth
      email
      firstName
      gender
      phoneNumber
      street
      state
      lastName
      postalCode
      city
    }
  }
  getClinicProfile(data: {id: "${clinicId}"}) {
    data {
      about
      address
      contactDetails
      district
      logoLink
      mail
      name
      pinCode
      registrationNumber
      clinicLogoHeader
    }
  }
}`;
export const GET_CLINIC_PROFILE = (clinicId) => gql`
query MyQuery {
  getClinicProfile(data: {id: "${clinicId}"}) {
    data {
      about
      address
      contactDetails
      district
      logoLink
      mail
      name
      pinCode
      registrationNumber
      patientTermsAndConditionsForWeb
      patientTermsAndConditionsForWebGeneric
    }
  }
}`;

export const GET_FILE_FOR_DOCG = gql`
  query MyQuery($fileType: GenericUploadFileType!) {
    getFileForDocg(fileType: $fileType) {
      data {
        url
        createdAt
      }
      message
      statusCode
    }
  }
`;
export const GET_FILE_FOR_CLINIC = gql`
  query MyQuery($fileType: GenericUploadFileType!) {
    getGenericTermsFileForClinic(fileType: $fileType) {
      data {
        url
      }
      message
      statusCode
    }
  }
`;
export const RAZORPAY_PLANS = gql`
  query MyQuery {
    listAllRazorpayPlans(isActive: true) {
      data {
        activePlan
        appointmentsAllowed
        createdAt
        doctorsAllowed
        entity
        interval
        item {
          active
          amount
          createdAt
          currency
          description
          itemId
          name
          type
          unitAmount
          updatedAt
        }
        patientsAllowed
        subscriptionType
        period
        planId
      }
      message
      statusCode
    }
  }
`;

export const LIST_DIAGNOSIS_TEMPLATES = gql`
  query MyQuery($search: String!) {
    listDiagnosisTemplates(search: $search) {
      message
      statusCode
      data {
        diagnosisName
        specialization
      }
    }
  }
`;

export const LIST_NOTES_TEMPLATE = gql`
  query MyQuery($filters: String!, $search: String!) {
    listDiagnosisNotesTemplates(filters: $filters, search: $search) {
      data {
        additionalAdvice
        diagnosis {
          diagnosisName
          message
        }
        diagnosisTemplate
        immunizationStatus
        instruction
        observation
        nutritionalAdvice
        planOfCare
        procedure {
          message
          procedureName
        }
        prognosis
        templateName
      }
      message
      statusCode
    }
  }
`;
