import React, { Fragment, useState, useEffect } from "react";
import { isMobileOnly } from "react-device-detect";
import { Row, Col, Card, Button, Image } from "react-bootstrap";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { useStore } from "src/store/Store";
import "./TodoList.css";
import Note from "../../../assets/icons/ToDoListIcon.svg";
import AddToDoList from "../../../shared/Modals/AddToDoList";
import "./TodoList.scss";
import mobiscroll from "@mobiscroll/react";
import { Checkbox } from "@mobiscroll/react5";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { getTodoList, updateToDoNote } from "./api";

mobiscroll.settings = {
  theme: "ios",
  themeVariant: "light",
};
const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;

function PriorityCellRenderer(params) {
  return (
    <span className={`toDo ${params.value.toLowerCase()}`}>{params.value}</span>
  );
}

const TodoList = () => {
  const { dispatchKPI } = useStore();

  // eslint-disable-next-line
  const [loader, setLoader] = React.useState(false);
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [displayRecords, setDisplayRecords] = useState("00");
  const [totalRecords, setTotalRecords] = useState(0);
  const [lgShow, setLgShow] = useState(false);
  const [data, setData] = useState(["00"]);

  function StatusCellRenderer(params) {
    const handleChange = async (e) => {
      e.target.disabled = true;
      const res = await updateToDoNote({
        id: params.data.id,
        status: e.target.checked,
      });
      if (res) {
        e.target.disabled = false;
        fetchData();
      }
    };
    return <Checkbox checked={params.value} onChange={handleChange} />;
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onQuickFilterChanged = () => {
    gridApi.setQuickFilter(document.getElementById("todoFilter").value);
  };

  const onPageSizeChanged = (e) => {
    console.log("PAGE chnged", e);
    setDisplayRecords(e.valueText);
  };

  const fetchData = async () => {
    setLoader(true);
    let _displayRecords = +displayRecords;
    if (displayRecords <= 3) {
      _displayRecords = 3;
    }
    const res = await getTodoList(_displayRecords);
    if (res?.data) {
      setRowData(res.data);
      let value =
        res?.data.length < 10 ? `0${res?.data.length}` : `${res.data.length}`;
      setData((prevProps) => {
        let arr = [];
        for (let index = 0; index <= res.totalCount; index++) {
          let value = index < 10 ? `0${index}` : index;
          arr.push(value);
        }
        return arr;
      });
      setDisplayRecords(value);
      setTotalRecords(res.totalCount);
      let pendingTask = res.data.filter((task) => !task.status);
      dispatchKPI({ type: "PENDING_TASK", payload: pendingTask.length });
    }
    setLoader(false);
  };

  useEffect(() => {
    console.log("display records", displayRecords);
    fetchData();
  }, [displayRecords]);
  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    suppressMovable: true,
    minWidth: 100,
  };

  // if we had column groups, we could provide default group items here
  const defaultColGroupDef = {};

  const cellStyle = (params) => {
    return params.data.status
      ? { textDecoration: "line-through", color: "#818181" }
      : null;
  };

  return (
    <Fragment>
      <Card className="docg-card todo_list">
        <CardHeader className="d-flex justify-content-between cardHeaderUsed">
          <CardTitle className="cardTitleUsed">
            <Image src={Note} alt="" className="mr-2" /> To-do List
          </CardTitle>
          {!isMobileOnly && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="docg-table-search">
                <input
                  type="text"
                  onInput={() => onQuickFilterChanged()}
                  id="todoFilter"
                  placeholder="Search..."
                />
              </div>
              <Button
                className="theme-button btn-xs"
                onClick={() => setLgShow(true)}
                title="Add To-do List"
              >
                <span className="material-icons pr-1">add_circle_outline</span>
                Add
              </Button>
            </div>
          )}
        </CardHeader>
        <CardBody className="docg-card-body p-0">
          {!isMobileOnly && (
            <div className="records-display">
              <span>Showing&nbsp;</span>
              <label className="record-selection-dropdown" htmlFor="">
                {totalRecords >= 0 && (
                  <mobiscroll.Select
                    touchUi={false}
                    onChange={onPageSizeChanged}
                    value={displayRecords}
                  >
                    {data.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </mobiscroll.Select>
                )}
                <i className="fas fa-chevron-down"></i>
              </label>
              <span>&nbsp;Records</span>
            </div>
          )}
          <div
            className="ag-theme-alpine"
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <AgGridReact
              rowData={rowData}
              defaultColDef={defaultColDef}
              frameworkComponents={{
                priorityCellRenderer: PriorityCellRenderer,
                StatusCellRenderer,
              }}
              onGridReady={onGridReady}
              defaultColGroupDef={defaultColGroupDef}
              enableRangeSelection={true}
              rowSelection={"multiple"}
              suppressAggFuncInHeader={true}
              pagination={true}
              paginationPageSize={3}
              suppressColumnVirtualisation={true}
              domLayout={"autoHeight"}
              animateRows={true}
            >
              <AgGridColumn
                headerName=""
                field="status"
                tooltipField="status"
                cellRenderer="StatusCellRenderer"
                maxWidth={50}
              ></AgGridColumn>
              <AgGridColumn
                headerName="To-do list items"
                headerTooltip="To-do list items"
                field="name"
                tooltipField="name"
                minWidth={220}
                cellStyle={cellStyle}
              ></AgGridColumn>
              <AgGridColumn
                headerName="Date"
                headerTooltip="Date"
                cellStyle={cellStyle}
                field="date"
                valueFormatter={(params) => {
                  return new Date(params.value).toLocaleString([], {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  });
                }}
                tooltipValueGetter={(params) => {
                  return new Date(params.value).toLocaleString([], {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  });
                }}
              ></AgGridColumn>
              <AgGridColumn
                cellStyle={cellStyle}
                headerName="Priority"
                headerTooltip="Priority"
                field="priority"
                tooltipField="priority"
                cellRenderer="priorityCellRenderer"
              ></AgGridColumn>
            </AgGridReact>
          </div>
        </CardBody>
      </Card>

      {setLgShow ? (
        <AddToDoList
          fetchData={fetchData}
          setLgShow={setLgShow}
          displayRecords={displayRecords}
          lgShow={lgShow}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default TodoList;
