import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import downloadIcon from "src/assets/download.svg";

const PDFViewer = ({ link, title, show, setShow, fileName, customButton }) => {
  console.log(link);
  const savePdf = () => {
    const aLink = document.createElement("a");
    aLink.href = link;
    aLink.download = fileName || "test";
    aLink.click();
    aLink.remove();
  };
  return (
    <Modal
      onHide={() => setShow(false)}
      size="lg"
      show={show}
      centered
      // onHide={handleNotificationsView}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header
        className="align-items-center d-flex"
        closeButton={customButton ? false : true}
      >
        <Modal.Title id="example-modal-sizes-title-lg" className="ml-3">
          <span className="ModalTitle">{title}</span>
        </Modal.Title>
        <div className="w-100"></div>
        <img
          style={{
            maxHeight: "1.25rem",
            maxWidth: "1.25rem",
            height: "1.25rem",
            width: "auto",
          }}
          onClick={savePdf}
          src={downloadIcon}
          className="mr-3"
          alt=""
        />
        {customButton}
      </Modal.Header>
      <Modal.Body
        className="d-flex justify-content-center align-items-center"
        style={{ maxHeight: "82vh", overflow: "auto" }}
      >
        {
          <iframe
            title="pdf"
            src={link}
            style={{ width: "600px", height: "500px" }}
            frameborder="0"
          ></iframe>
        }
      </Modal.Body>
    </Modal>
  );
};

export default PDFViewer;
