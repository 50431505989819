import { NavItem, TabNav } from "@mobiscroll/react";
import { Input } from "@mobiscroll/react5";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { Fragment } from "react";
import { Card, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import allInvoice from "src/assets/all-invoice.svg";
import CallIcon from "src/assets/call.svg";
import InstituteIcon from "src/assets/cancer-institute.svg";
import downloadIcon from "src/assets/download.svg";
import invoiceDetailSvg from "src/assets/invoice-purple.svg";
import Loader from "src/components/Dashboard/common/Loader";
import getImageBlobFromUrl from "src/lib/getImageBlobFromUrl";
import Invoice from "./Invoice";
import "./Invoice.scss";

import { getQuery } from "src/components/ApolloConnection/ApolloProviderComponent";
import { GET_CLINIC_ID } from "src/components/graphql/GraphQLquery";
import useInvoicePdf from "../Patient/PatientDetail/DownloadInvoice";
import PDFViewer from "src/components/Dashboard/Layouts/PDFViewer";
import { useState } from "react";

const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;
function InvoiceDetail(props) {
  const { id } = useParams();
  const {
    downloadInvoice,
    isInvoiceLoading,
    invoiceData,
    subTotal,
    total,
    tax,
    invoiceBase64,
    fetchInvoiceData,
    invoiceFileName,
  } = useInvoicePdf(id);
  const history = useHistory();
  const [activeTab, setActiveTab] = React.useState("invoice-detail");
  const [logoLink, setLogolink] = React.useState(null);
  const [loader, setLoader] = React.useState(false);

  React.useEffect(async () => {
    fetchInvoiceData();
    setLoader(true);
    try {
      const _res = await getQuery(GET_CLINIC_ID);
      const logoLink = _res.data.listClinics?.data[0].logoLink;
      const logoLinkBlob = await getImageBlobFromUrl(logoLink);
      setLogolink(logoLinkBlob);
    } catch (err) {
      console.log("Errow whle setting invoice detials", err);
    }
    setLoader(false);
  }, []);

  const [gridApi, setGridApi] = React.useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = React.useState(null);
  const [displayRecords, setDisplayRecords] = React.useState("03");
  const [showPDF, setShowPDF] = useState(false);

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 100,
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const AmountRenderer = (params) => {
    let amount = +params?.data?.amount;
    return <span>&#8377; {amount?.toLocaleString("en-IN")}</span>;
  };

  const defaultColGroupDef = {};
  return activeTab === "invoice-detail" ? (
    <Fragment>
      <PDFViewer
        link={invoiceBase64}
        show={showPDF}
        setShow={setShowPDF}
        fileName={invoiceFileName}
      />
      <Loader show={loader} />
      {/* Main Content start Here  */}
      <Card className="docg-card docg-appointment docg-invoice patient-wrapper card-wrapper mt-0 mb-0">
        <CardHeader className="docg-card-header pb-0 customHeader d-flex justify-content-between align-items-center">
          <TabNav className="docg-tabs" theme="windows" context="#tab-content">
            <NavItem
              selected={activeTab === "allinvoice"}
              onClick={() => {
                history.push("/invoice");
                setActiveTab("allinvoice");
              }}
            >
              <img src={allInvoice} alt="" />
              All Invoices
            </NavItem>
            <NavItem
              selected={activeTab === "invoice-detail"}
              onClick={() => setActiveTab("invoice-detail")}
            >
              <img src={invoiceDetailSvg} alt="" />
              Invoice Detail
            </NavItem>
          </TabNav>
          <div className="d-flex justify-content-end align-items-center">
            {logoLink && !isInvoiceLoading && (
              <button
                onClick={async () => {
                  setShowPDF("invoice");
                }}
                className="docg-outline-btn"
              >
                View Invoice
              </button>
            )}
          </div>
        </CardHeader>
        <CardBody className="docg-card-body pl-4 pr-4">
          <div>
            <p className="purple-bold mt-2 mb-2">Invoice To</p>
            <div className="mbsc-row">
              <div className="col-lg-6">
                <Input
                  inputStyle="underline"
                  labelStyle="floating"
                  label="Name"
                  type="text"
                  placeholder=""
                  className="invoice-field patientSearch"
                  list="search"
                  defaultValue={invoiceData?.to}
                  readOnly
                />
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-6">
                    <Input
                      labelStyle="floating"
                      inputStyle="underline"
                      label="Invoice No"
                      type="text"
                      placeholder=""
                      className="invoice-field width-100"
                      readOnly
                      value={invoiceData?.uhid}
                    />
                  </div>
                  <div className="col-6">
                    <Input
                      labelStyle="floating"
                      inputStyle="underline"
                      label="Payment Mode"
                      type="text"
                      placeholder=""
                      className="invoice-field width-100"
                      readOnly
                      value={invoiceData?.paymentMethod}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Input
                  labelStyle="floating"
                  inputStyle="underline"
                  label="Address"
                  readOnly
                  type="text"
                  placeholder=""
                  className="invoice-field"
                  value={invoiceData?.toAddress ? invoiceData.toAddress : "-"}
                />
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6">
                    <Input
                      inputStyle="underline"
                      labelStyle="floating"
                      label="Created at"
                      type="text"
                      placeholder=""
                      className="invoice-field width-100"
                      readOnly
                      value={
                        invoiceData?.createdAt
                          ? new Date(invoiceData.createdAt).toLocaleTimeString(
                              [],
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              }
                            )
                          : "-"
                      }
                    />
                  </div>
                  <div className="col-lg-6">
                    <Input
                      inputStyle="underline"
                      labelStyle="floating"
                      label="Status"
                      type="text"
                      placeholder=""
                      className="invoice-field width-100"
                      readOnly
                      defaultValue={invoiceData?.status}
                    />
                  </div>
                </div>
              </div>
            </div>
            {invoiceData?.items && (
              <div className="mt-4">
                <Card className="docg-card">
                  <Card.Body className="docg-card-body p-0">
                    <div
                      className="ag-theme-alpine"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <AgGridReact
                        rowData={invoiceData.items}
                        frameworkComponents={{
                          amountRenderer: AmountRenderer,
                        }}
                        defaultColDef={defaultColDef}
                        defaultColGroupDef={defaultColGroupDef}
                        enableRangeSelection={true}
                        pagination={true}
                        onGridReady={onGridReady}
                        paginationPageSize={displayRecords}
                        domLayout={"autoHeight"}
                        animateRows={true}
                      >
                        <AgGridColumn
                          headerName="SI."
                          headerTooltip="SI."
                          headerClass="custom-header"
                          field="serialNumber"
                          tooltipField="serialNumber"
                          initialPinned="left"
                          initialSort="asc"
                        ></AgGridColumn>
                        <AgGridColumn
                          headerName="Description"
                          headerTooltip="Description"
                          headerClass="custom-header"
                          field="description"
                          tooltipField="description"
                          suppressSizeToFit
                          autoHeight
                          wrapText
                          flex={2}
                        ></AgGridColumn>
                        <AgGridColumn
                          headerClass="custom-header"
                          headerName="Type"
                          headerTooltip="Type"
                          field="type"
                          tooltipField="type"
                        ></AgGridColumn>
                        <AgGridColumn
                          headerClass="custom-header"
                          headerName="CGST"
                          headerTooltip="CGST"
                          field="cgst"
                          tooltipField="cgst"
                        ></AgGridColumn>
                        <AgGridColumn
                          headerClass="custom-header"
                          headerName="SGST"
                          headerTooltip="SGST"
                          field="sgst"
                          tooltipField="sgst"
                        ></AgGridColumn>
                        <AgGridColumn
                          headerTooltip="Total Amount"
                          headerName="Total Amount"
                          headerClass="custom-header"
                          field="amount"
                          tooltipField="amount"
                          cellRenderer="amountRenderer"
                        ></AgGridColumn>
                      </AgGridReact>
                    </div>
                  </Card.Body>
                </Card>
                <hr />
              </div>
            )}
            <div className="row add-bottom-border d-flex justify-content-end pb-2">
              {/* <div className="d-flex align-items-center mb-2">
                      <p className="total-tax">Sub Total :</p>
                      <p className="total-amount-tax">
                        &#8377;{invoiceData?.SubTotal}
                      </p>
                    </div> */}
              {/* <div className="d-flex align-items-center mb-2">
                      <p className="total-tax">Tax :</p>
                      <p className="total-amount-tax">{invoiceData?.Tax}%</p>
                    </div> */}
              <div className="d-flex flex-column align-items-end">
                <div className="d-flex align-items-center total-amount-wrapper">
                  <p className="total-tax text-white">Sub Total :</p>
                  <p className="total-amount-tax text-white">
                    &#8377; {subTotal?.toLocaleString("en-IN")}
                  </p>
                </div>
                <div className="d-flex align-items-center total-amount-wrapper">
                  <p className="total-tax text-white">Tax :</p>
                  <p className="total-amount-tax text-white">
                    {tax?.toLocaleString("en-IN")}
                  </p>
                </div>
                <div className="d-flex align-items-center total-amount-wrapper">
                  <p className="total-tax text-white">Total :</p>
                  <p className="total-amount-tax text-white">
                    &#8377; {total?.toLocaleString("en-IN")}
                  </p>
                </div>
              </div>
            </div>
            <div id="footer" className="row mt-4 align-items-center">
              <Col xs={12} sm={5} md={7} lg={7} xl={8}>
                <div className="signature-div">
                  <img src={invoiceData?.signature} alt="" />
                </div>
                <b>{invoiceData?.doctorName}</b>
              </Col>
              <Col
                className="d-flex justify-content-end flex-column"
                xs={12}
                sm={7}
                md={5}
                lg={5}
                xl={4}
              >
                <div className="d-flex align-items-center mb-2">
                  <img src={CallIcon} alt="" />
                  <p className="total-amount-tax ml-2">
                    {invoiceData?.doctorPhoneNumber}
                  </p>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <img src={InstituteIcon} alt="" />
                  <p className="total-amount-tax ml-2">
                    {invoiceData?.fromAddress ? invoiceData?.fromAddress : "-"}
                  </p>
                </div>
              </Col>
            </div>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  ) : (
    <Invoice />
  );
}

export default InvoiceDetail;
